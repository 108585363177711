@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

body {
  background: url(../images/header3.png) no-repeat scroll 50% 0 #efece7 !important;
}
.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: transparent;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 90% 53%;
    }
    #head {
      height: 260px;
      .uio-app-name {
        display: none;
      }
      .head-menu {
        right: 110px;
        top: 15px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-component .vrtx-dropdown-wrapper {
          top: 30px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        color: #030303;
        min-height: 200px;
        font-family: "Raleway", sans-serif;
        padding-bottom: 50px;
        display: block;
        height: 100%;
        .logo {
          height: auto;
          margin-left: -5px;
          margin-top: 110px;
          position: absolute;
          max-width: 100%;
        }
        .funding-programme  {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          padding-top: 14px !important;
          position: absolute;
          top: 180px;
          width: 512px;
        }
        .eu-flag {
          margin-top: 105px;
          position: absolute;
          right: 0px;
        }
        .particles {
          left: 530px;
          bottom: 35px;
          position: absolute;
        }
        img {
        }
        .logo-responsive {
          display: none;
        }
      }
    }
  }
}
#globalnav {
  background: transparent none repeat scroll 0 0;
  ul {
    background: @color-primary;
    border-radius: 3px;
  }
  ul li {
    a {
      padding: 10px 15px 11px;
    }
    &:first-of-type a {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}
#main  {
  padding-top: 40px;
  margin-top: 0;
  background-color: @color-light;
  overflow: hidden;
  #vrtx-main-content {
    p.sliderinfo {
    }
    .vrtx-frontpage-box {
      height: 100% !important;
      padding: 1em 2em;
      &.half-box-left {
        border-right: 2px solid @color-neutral--light;
      }
      &.half-box-right {
        float: right;
        border-left: 2px solid @color-neutral--light;
      }
      &#vrtx-main-content-2 {
        //min-height: 643px;
      }
      &#vrtx-main-content-6 {
        //min-height: 394px;
      }
      .vrtx-feed.with-images li {
        border-bottom: 1px solid @color-neutral--light;
        padding-bottom: 1em;
        &.item-last {
          border-bottom: none;
        }
      }
    }
  }
  #vrtx-additional-content {
    margin-left: 0px;
    background: @color-light;
    .grid-container:last-of-type {
      margin-bottom: 0px;
    }
    .vrtx-frontpage-box {
      padding: 1em;
    }
    .vrtx-frontpage-box {
      border-bottom: 4px solid @color-neutral--light;
    }
    hr {
      border-bottom: 1px solid @color-neutral--light;
    }
  }
  .button-large-blue {
    background-color: @color-link;
    background-image: url(../images/arrow-white-right.svg);
    &:hover,
    &:focus {
      background-color: #1c5288;
    }
  }
}
#vrtx-frontpage-introduction {
  background: @color-light;
  padding: 1em;
}

#left-main ul.vrtx-breadcrumb-menu {
  background: @color-light;
  li {
    &.vrtx-ancestor:hover a:after {
      border-color: #e4e4e4 transparent transparent transparent;
    }
    &.vrtx-child {
      border-bottom: 1px @color-neutral--light solid;
      a {
        color: @color-link;
        background-color: @color-neutral--light;
        border-top: none !important;
        background: rgba(0, 0, 0, 0)
          url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
          scroll 10px 23px;
      }
    }
    a {
      &.vrtx-marked {
        background-color: @color-primary !important;
        color: @color-light;
        border-top: none !important;
        border-color: @color-primary;
      }
      &:hover,
      &:focus {
        background-color: #e4e4e4;
        border-color: #e4e4e4;
      }
    }
    span::before,
    a::before {
      content: " " !important;
    }
  }
}
.vrtx-resources h2:first-child {
  display: block;
  border-top: 4px solid @color-neutral--light !important;
  margin-right: 0px;
}
.vrtx-frontpage-full-width.total-main #main {
  margin: 0 auto;
  width: 970px;
}
#main #vrtx-main-content .vrtx-frontpage-box {
  &.half-box-right,
  &.third-box-right {
    border-left: 0;
  }
  &.half-box-left,
  &.third-box-left {
    border-right: 0;
  }
}

#right-main {
  background: @color-light;
  #vrtx-content {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    #vrtx-main-content {
      padding: 0px !important;
      #vrtx-main-user {
        table {
          font-size: 13px !important;
          line-height: 1.4;
        }
      }
    }
    #vrtx-additional-content #vrtx-related-content {
      background: #f4f4f4;
      padding: 1em;
      overflow: hidden;
      font-size: 14px;
      a {
        font-size: 15px;
      }
    }
  }
  h2,
  .vevent {
    max-width: 100%;
  }
  .vrtx-subscribe-component {
    padding: 0px 1em 28px 1em;
  }
  .vrtx-resources:first-of-type h2 {
    border-top: none !important;
  }
  .vrtx-last-event .vevent {
    border-bottom: none !important;
  }
  .vevent {
    border-bottom: 1px solid @color-neutral--light !important;
    padding-bottom: 20px !important;
  }
  .vrtx-default-article {
    border-bottom: 1px solid @color-neutral--light !important;
    &:last-of-type {
      border-bottom: none !important;
    }
  }
  .vrtx-person-listing thead tr th,
  .vrtx-person-listing tbody tr,
  .vrtx-person-listing tr td,
  .vrtx-person-listing tr th,
  #vrtx-person-contact-info-extras {
    border-color: @color-neutral--light !important;
  }
}
table th {
  font-weight: bold;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
  figure {
    margin: 20px 20px !important;
  }
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

#main .vrtx-back a:before {
  background: url(../images/icon-chevron-back-blue.svg) 0 0.2ex no-repeat;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg")
    no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}
hr {
  border-bottom: 4px solid @color-neutral--light;
}
.vrtx-authors,
.vrtx-date-info,
.vrtx-tags,
.vrtx-paging-feed-wrapper {
  border-top: 6px solid @color-neutral--light !important;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background: transparent;
    #footers {
      background-color: @color-primary !important;
      padding: 15px;
      border-radius: 3px;
      .responsible-login .content {
        float: right;
      }
    }
    *,
    a {
      color: @color-light;
    }
    h2,
    .menu-label {
      color: @color-lines;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0)
        url("../uio1/images/dropdown.png") no-repeat
        scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      #head {
        #header {
          .logo-responsive {
            display: block;
          }
          .funding-programme {
            display: inline-block;
            padding: 1em;
            position: absolute;
            top: auto;
            font-size: 14px;
            bottom: -4px;
            width: auto;
          }
          .eu-flag,
          .particles,
          .logo {
            display: none;
          }
          img {
            padding: 0 10px 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #main {
    padding: 15px !important;
    #vrtx-main-content .vrtx-frontpage-box.half-box-left,
    #vrtx-main-content .vrtx-frontpage-box.half-box-right {
      border-left: none;
      border-right: none;
    }
    #vrtx-main-content-7 {
      img.image-left {
        width: 100%;
        display: block;
        max-width: 100% !important;
        margin: 0px auto;
        padding: 0px 1.5em 0 0;
      }
      p {
        margin-left: 0px !important;
      }
    }
  }
  .grid-container,
  .vrtx-frontpage-box {
    border-color: @color-neutral--light !important;
  }
  #vrtx-additional-content {
    margin-top: 20px !important;
    background: transparent !important;
    .vrtx-frontpage-box {
      &.vrtx-additional-content-last {
        border-bottom: none !important;
      }
    }
  }
  #footer-wrapper  {
    background: @color-primary !important;
    padding: 0px;
  }
  #footers {
    padding-top: 1em !important;
    .responsible-login .content {
      float: left !important;
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #880100;
@color-primary--dark : darken(spin(@color-primary, -5), 10%);
@color-secondary : #e4e0d9;
@color-secondary--dark : darken(spin(@color-secondary, -5), 10%);
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #efece8;
@color-neutral--air : #e5e5e5;
@color-lines : #e4e4e4;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : @color-neutral--light;

// Spacing
@unit : 5px;
@spacing--xlarge : 100px;
@spacing--large : 40px;
@spacing : 20px;
@spacing--small : 10px;
